@import url("https://fonts.googleapis.com/css?family=Josefin+Sans|Open+Sans&display=swap");
/**
* The geosuggest module
* NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
*/
.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  width: 100%;
  /* margin: 1em auto; */
  text-align: left; }

.geosuggest__input {
  width: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 1px #3d464d;
  padding: 0.5em 1em;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s;
  background: #fff;
  color: #000; }

.geosuggest__input:focus {
  border-color: #ccc;
  box-shadow: 0 0 0 transparent; }

.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 2px solid #ccc;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s; }

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0; }

/**
  * A geosuggest item
  */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer; }

.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #ccc; }

.geosuggest__item--active {
  background: #999999;
  color: #000; }

.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc; }

.geosuggest__item__matched-text {
  font-weight: bold; }

body {
  background: #f2edeb;
  color: #000;
  font-family: "Open Sans", sans-serif; }

.no-marg {
  margin: 0 !important; }

.no-pad {
  padding: 0 !important; }

.padding {
  padding: 1rem !important; }

.selectable {
  cursor: pointer; }

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .container .main-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%; }
    .container .main-menu a:hover {
      color: #fff; }

.main {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%; }

.footer {
  width: 100%;
  height: 40px;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white; }
  .footer h3,
  .footer p {
    margin: 0.1rem; }
  .footer .ui.menu {
    width: 100%; }

.ui.segment.wrapper {
  padding: 0;
  margin: 0; }

.ui.segment.pageHolder {
  margin: 1rem; }

.ui.menu {
  font-family: Josefin, "Open Sans", sans-serif;
  letter-spacing: 0.3rem;
  font-weight: 900;
  font-size: 1.2rem;
  margin-bottom: 0;
  border-radius: 0; }

.ui.button {
  font-family: Josefin, "Open Sans", sans-serif;
  letter-spacing: 0.1rem; }

.ui.form .formActions {
  justify-content: space-between;
  display: flex;
  border-top: 1px solid rgba(151, 149, 149, 0.699);
  padding: 1rem;
  margin-top: 0.4rem; }

.ui.segment.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(151, 149, 149, 0.699); }
  .ui.segment.pageHeader h1.ui.header {
    color: rgba(115, 114, 114, 0.8);
    font-family: Josefin, "Open Sans", sans-serif;
    letter-spacing: 0.3rem;
    font-weight: 900;
    margin: 0; }

.ui.modal .ui.header.modalHeader {
  color: rgba(115, 114, 114, 0.8);
  font-family: Josefin, "Open Sans", sans-serif;
  letter-spacing: 0.3rem;
  font-weight: 900;
  background: #efefef; }

.ui.grid .gridHeader {
  background: #eeeeee;
  color: rgba(115, 114, 114, 0.9);
  font-family: Josefin, "Open Sans", sans-serif;
  letter-spacing: 0.1rem;
  font-weight: bold; }

.ui.statistics .statistic > .label,
.ui.statistic > .label {
  color: rgba(115, 114, 114, 0.8);
  font-family: Josefin, "Open Sans", sans-serif;
  letter-spacing: 0.3rem;
  font-weight: 900; }

.ui.statistics .statistic > .value,
.ui.statistic > .value,
.ui.small.statistic > .value,
.ui.small.statistics .statistic > .value {
  font-family: Josefin, "Open Sans", sans-serif;
  letter-spacing: 0.3rem;
  font-weight: 900;
  font-size: 2rem !important; }

.ui.header.tableHeader {
  color: rgba(115, 114, 114, 0.8);
  font-family: Josefin, "Open Sans", sans-serif;
  letter-spacing: 0.3rem;
  font-weight: 900; }

.column.smallStat {
  flex-direction: column;
  display: flex !important;
  align-items: center;
  justify-content: center;
  justify-items: center; }
  .column.smallStat .ui.header {
    margin: 0; }
  .column.smallStat p {
    margin: 0;
    color: rgba(115, 114, 114, 0.8);
    font-family: Josefin, "Open Sans", sans-serif;
    letter-spacing: 0.3rem;
    font-weight: 900;
    text-align: center; }
  .column.smallStat i {
    color: rgba(115, 114, 114, 0.8); }

.login {
  justify-content: center; }

.ui.table thead th {
  font-weight: bold;
  font-size: 1.1rem; }

.ui.buttons:not(.basic):not(.inverted) > .button,
.ui.buttons > .ui.button:not(.basic):not(.inverted) {
  border: 1px solid white; }

.noAccess .ui.checkbox label {
  color: red; }
